<p
  [innerText]="'CAMPAIGN.FORM.FIELD.THEME_EXAMPLE' | translate"
  class="text-primary mt-3 text-bold"
></p>
<div id="theme-sample-container" class="w-100 p-1">
  <div id="theme-sample" class="p-4" [style.background-color]="backgroundColor">
    <div class="card">
      <div class="card-body">
        <div class="d-flex gap-3">
          <div>
            <div id="theme-sample-logo"></div>
          </div>
          <div>
            <h4 class="m-0" [style.color]="textColor">Pharmacy Name</h4>
            <address class="text-tertiary m-0">
              <span class="d-flex gap-3">
                123 Street Address
                <span [style.color]="textColor">1.2 mi</span>
              </span>
              <span>City, State</span>
            </address>
          </div>
        </div>

        <div class="d-flex gap-2 mt-4">
          <app-theme-example-button
            [textColor]="textColor"
            [tintColor]="tintColor"
            [tintActiveColor]="tintActiveColor"
            [time]="'1:00 PM'"
          ></app-theme-example-button>
          <app-theme-example-button
            [textColor]="textColor"
            [tintColor]="tintColor"
            [tintActiveColor]="tintActiveColor"
            [time]="'1:30 PM'"
          ></app-theme-example-button>
        </div>
      </div>
    </div>
  </div>
</div>
