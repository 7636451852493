<div
  (mouseenter)="isExampleButtonHovered = true"
  (mouseleave)="isExampleButtonHovered = false"
  [style.border-color]="isExampleButtonHovered ? tintActiveColor : tintColor"
  [style.background-color]="isExampleButtonHovered ? tintActiveColor : ''"
  [style.color]="isExampleButtonHovered ? '#fff' : textColor"
  class="btn d-flex flex-column align-items-center time-button btn-outline-primary"
>
  {{ time }}
  <span class="text-tertiary">Today</span>
</div>
