export const API_RETRY_LIMIT = 3; // number of times to retry failed API requests

export const DEFAULT_THEME = {
  backgroundColor: '#F5F8FF',
  backgroundColorDark: '#080808',
  textColor: '#161616',
  textColorDark: '#f6f6f6',
  tintColor: '#385d82',
  tintColorDark: '#94a7bb',
  tintActiveColor: '#386DA2',
  tintActiveColorDark: '#A3B2C1',
};
